export const stockTickers = [
  // Technology Stocks
  'AAPL',
  'MSFT',
  'GOOGL',
  'AMZN',
  'META',
  'NVDA',
  'TSLA',
  'JPM',
  'BAC',
  'WFC',
  'AVGO',
  'ADBE',
  'QCOM',
  'AMD',
  'TXN',
  'INTC',
  'CRM',
  'NOW',

  // Financial Stocks
  'BRK.B',
  'V',
  'MA',
  'GS',
  'BLK',
  'AXP',
  'MS',
  'SCHW',
  'C',
  'SPGI',
  'CB',
  'PGR',
  'TFC',
  'BK',
  'USB',
  'AIG',
  'MMC',

  // Consumer Goods Stocks
  'PG',
  'KO',
  'PEP',
  'COST',
  'WMT',
  'HD',
  'MCD',
  'NKE',
  'SBUX',
  'TGT',
  'LOW',
  'EL',
  'CL',
  'GIS',
  'KMB',
  'HSY',
  'K',
  'SYY',
  'MKC',
  'CLX',

  // Energy Stocks
  'XOM',
  'CVX',
  'COP',
  'SLB',
  'EOG',
  'MPC',
  'PSX',
  'VLO',
  'OXY',
  'PXD',
  'KMI',
  'WMB',
  'HAL',
  'DVN',
  'BKR',
  'HES',
  'OKE',
  'FANG',
  'MRO',
  'APA',

  // Healthcare Stocks
  'UNH',
  'LLY',
  'JNJ',
  'ABBV',
  'MRK',
  'PFE',
  'TMO',
  'ABT',
  'DHR',
  'BMY',
  'AMGN',
  'CVS',
  'ISRG',
  'GILD',
  'MDT',
  'ELV',
  'VRTX',
  'SYK',
  'ZTS',
  'BSX',

  // Industrial Stocks
  'RTX',
  'HON',
  'CAT',
  'UPS',
  'BA',
  'LMT',
  'GE',
  'MMM',
  'DE',
  'ITW',
  'EMR',
  'FDX',
  'ETN',
  'PH',
  'NSC',
  'CSX',
  'GD',
  'NOC',
  'WM',
  'ROP',

  // Real Estate Stocks
  'PLD',
  'AMT',
  'EQIX',
  'CCI',
  'PSA',
  'O',
  'WELL',
  'SPG',
  'VICI',
  'DLR',
  'AVB',
  'EQR',
  'CBRE',
  'ARE',
  'MAA',
  'BXP',
  'UDR',
  'VTR',
  'EXR',
  'KIM',

  // Telecommunications Stocks
  'TMUS',
  'VZ',
  'T',
  'CMCSA',
  'CHTR',
  'NFLX',
  'DIS',
  'ATVI',
  'EA',
  'DISH',

  // Utilities Stocks
  'NEE',
  'DUK',
  'SO',
  'D',
  'SRE',
  'AEP',
  'EXC',
  'XEL',
  'PCG',
  'WEC',

  // Materials Stocks
  'LIN',
  'APD',
  'SHW',
  'FCX',
  'ECL',
  'NEM',
  'NUE',
  'CTVA',
  'DOW',
  'DD',

  // Other Notable Stocks
  'BLK',
  'INTU',
  'BKNG',
  'PANW',
  'UBER',
  'AMAT',
  'ADI',
  'LRCX',
  'MU',
  'KLAC',

  // Popular ETFs
  'SPY', // SPDR S&P 500 ETF Trust
  'VOO', // Vanguard S&P 500 ETF
  'IVV', // iShares Core S&P 500 ETF
  'SCHA', // Schwab US Small Cap ETF
  'SPMD', // SPDR Portfolio S&P 400 Mid Cap ETF
  'XES', // SPDR S&P Oil & Gas Equipment & Services ETF
  'UNG', // United States Natural Gas Fund LP
  'FXY', // Invesco Currency Shares Japanese Yen Trust
  'SLV', // iShares Silver Trust
  'GDX', // VanEck Gold Miners UCITS ETF
  'GDXJ', // VanEck Junior Gold Miners UCITS ETF
  'IAUP', // iShares Gold Producers UCITS ETF USD (Acc)
];
