import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Mixpanel } from '../services/Mixpanel';

const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateToTimeline = () => {
    Mixpanel.track('navigate_to_timeline');
    navigate('/timeline');
  };

  return (
    <div className="relative min-h-screen overflow-hidden">
      {/* Hero Section */}
      <section className="relative py-20 px-4 sm:px-6 lg:px-8">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-purple-50" />
        <div className="relative max-w-7xl mx-auto">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            <div className="space-y-8">
              <div className="inline-block px-4 py-2 bg-blue-100 text-blue-800 rounded-full text-sm font-medium mb-4">
                🚀 AI-Powered Market Analysis
              </div>
              <h1 className="text-5xl md:text-6xl font-bold tracking-tight text-gray-900">
                Predict Market Moves with{' '}
                <span className="bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
                  AI Precision
                </span>
              </h1>
              <p className="text-gray-600 text-lg mb-8 max-w-2xl mx-auto">
                Transform your trading strategy with our cutting-edge AI
                platform. Analyze historical patterns, predict market movements,
                and make data-driven decisions that give you a competitive edge.
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
                <button
                  onClick={handleNavigateToTimeline}
                  className="px-8 py-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white font-medium rounded-lg shadow-sm hover:shadow-md hover:from-blue-700 hover:to-purple-700 transition-all duration-300 transform hover:-translate-y-0.5"
                >
                  Try it for free
                </button>
              </div>
              <div className="flex items-center gap-4 text-sm text-gray-500">
                <span>Trusted by 10,000+ traders worldwide</span>
              </div>
            </div>
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-2xl transform rotate-6" />
              <img
                src="images/hero-image.png"
                alt="Hero illustration showing stock prices and event analysis"
                className="relative rounded-2xl shadow-xl transform hover:scale-105 transition-transform duration-300"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 px-4 sm:px-6 lg:px-8 bg-white">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">
              Powerful Features for Smart Trading
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Everything you need to analyze market patterns and make informed
              trading decisions
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {/* Feature 1 */}
            <div className="group bg-white p-8 rounded-2xl shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 hover:border-blue-100 hover:-translate-y-1">
              <div className="flex justify-center mb-6">
                <div className="w-20 h-20 bg-blue-50 rounded-xl flex items-center justify-center group-hover:bg-blue-100 transition-colors duration-300">
                  <img
                    src="images/event-search.png"
                    alt="Event Search"
                    className="w-12 h-12"
                  />
                </div>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 text-center mb-4 group-hover:text-blue-600 transition-colors duration-300">
                Advanced Event Analysis
              </h3>
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-center">
                  <span className="text-blue-500 mr-2">✓</span>
                  Real-time event detection
                </li>
                <li className="flex items-center">
                  <span className="text-blue-500 mr-2">✓</span>
                  Historical pattern matching
                </li>
                <li className="flex items-center">
                  <span className="text-blue-500 mr-2">✓</span>
                  Custom scenario creation
                </li>
              </ul>
            </div>

            {/* Feature 2 */}
            <div className="group bg-white p-8 rounded-2xl shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 hover:border-purple-100 hover:-translate-y-1">
              <div className="flex justify-center mb-6">
                <div className="w-20 h-20 bg-purple-50 rounded-xl flex items-center justify-center group-hover:bg-purple-100 transition-colors duration-300">
                  <img
                    src="images/timeline.png"
                    alt="Timeline"
                    className="w-12 h-12"
                  />
                </div>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 text-center mb-4 group-hover:text-purple-600 transition-colors duration-300">
                Market Impact Analysis
              </h3>
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-center">
                  <span className="text-purple-500 mr-2">✓</span>
                  Price movement prediction
                </li>
                <li className="flex items-center">
                  <span className="text-purple-500 mr-2">✓</span>
                  Correlation scoring
                </li>
                <li className="flex items-center">
                  <span className="text-purple-500 mr-2">✓</span>
                  Risk assessment tools
                </li>
              </ul>
            </div>

            {/* Feature 3 */}
            <div className="group bg-white p-8 rounded-2xl shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 hover:border-indigo-100 hover:-translate-y-1">
              <div className="flex justify-center mb-6">
                <div className="w-20 h-20 bg-indigo-50 rounded-xl flex items-center justify-center group-hover:bg-indigo-100 transition-colors duration-300">
                  <img
                    src="images/analysis.png"
                    alt="Analysis"
                    className="w-12 h-12"
                  />
                </div>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 text-center mb-4 group-hover:text-indigo-600 transition-colors duration-300">
                Trading Insights
              </h3>
              <ul className="space-y-3 text-gray-600">
                <li className="flex items-center">
                  <span className="text-indigo-500 mr-2">✓</span>
                  Customizable dashboards
                </li>
                <li className="flex items-center">
                  <span className="text-indigo-500 mr-2">✓</span>
                  Real-time alerts
                </li>
                <li className="flex items-center">
                  <span className="text-indigo-500 mr-2">✓</span>
                  Exportable reports
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="py-20 px-4 sm:px-6 lg:px-8 bg-gradient-to-br from-blue-50 to-purple-50">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">
              Why Professional Traders Choose Us
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Join the community of successful traders who trust our platform
              for their market analysis
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-8">
            {/* Benefit 1 */}
            <div className="group bg-white/80 backdrop-blur-sm p-8 rounded-2xl border border-gray-100/50 hover:border-blue-100/50 hover:-translate-y-1 transition-all duration-300">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 bg-blue-100 rounded-lg flex items-center justify-center mr-4">
                  <span className="text-2xl">📈</span>
                </div>
                <h3 className="text-2xl font-semibold text-gray-900 group-hover:text-blue-600 transition-colors duration-300">
                  Smart Trading
                </h3>
              </div>
              <p className="text-gray-600">
                Our AI platform helps traders identify profitable patterns
                others miss.
              </p>
            </div>

            {/* Benefit 2 */}
            <div className="group bg-white/80 backdrop-blur-sm p-8 rounded-2xl border border-gray-100/50 hover:border-purple-100/50 hover:-translate-y-1 transition-all duration-300">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 bg-purple-100 rounded-lg flex items-center justify-center mr-4">
                  <span className="text-2xl">⚡</span>
                </div>
                <h3 className="text-2xl font-semibold text-gray-900 group-hover:text-purple-600 transition-colors duration-300">
                  Faster Analysis
                </h3>
              </div>
              <p className="text-gray-600">
                Save hours of research with our AI-powered analysis. Get
                comprehensive market insights in seconds, not days.
              </p>
            </div>

            {/* Benefit 3 */}
            <div className="group bg-white/80 backdrop-blur-sm p-8 rounded-2xl border border-gray-100/50 hover:border-indigo-100/50 hover:-translate-y-1 transition-all duration-300">
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 bg-indigo-100 rounded-lg flex items-center justify-center mr-4">
                  <span className="text-2xl">🛡️</span>
                </div>
                <h3 className="text-2xl font-semibold text-gray-900 group-hover:text-indigo-600 transition-colors duration-300">
                  Risk Management
                </h3>
              </div>
              <p className="text-gray-600">
                Make informed decisions with our risk assessment tools. Identify
                potential pitfalls before they impact your portfolio.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 px-4 sm:px-6 lg:px-8 bg-gradient-to-br from-blue-600 to-purple-600">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Ready to Transform Your Trading Strategy?
          </h2>
          <p className="text-xl text-white/90 mb-8">
            Join thousands of successful traders who trust our platform for
            their market analysis. Start your free trial today and experience
            the power of AI-driven insights.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button
              onClick={handleNavigateToTimeline}
              className="px-10 py-4 bg-white text-blue-600 font-medium rounded-lg shadow-sm hover:shadow-md transition-all duration-300 transform hover:-translate-y-0.5"
            >
              Try it for free
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
