import { GetTimelineRequest, GetTimelineResponse } from '../types';

// Cache duration in milliseconds (e.g., 1 hour)
const CACHE_DURATION = 60 * 60 * 1000;

interface CacheEntry {
  response: GetTimelineResponse;
  timestamp: number;
}

const getFromCache = (query: string): GetTimelineResponse | null => {
  try {
    const cacheKey = `timeline_${query}`;
    const cached = localStorage.getItem(cacheKey);
    if (!cached) return null;

    const { response, timestamp }: CacheEntry = JSON.parse(cached);

    // Check if cache is still valid
    if (Date.now() - timestamp > CACHE_DURATION) {
      localStorage.removeItem(cacheKey);
      return null;
    }

    // Convert date strings back to Date objects
    return {
      events: response.events.map((event) => ({
        ...event,
        date: new Date(event.date),
      })),
    };
  } catch (error) {
    console.error('Error reading from cache:', error);
    return null;
  }
};

const saveToCache = (query: string, response: GetTimelineResponse) => {
  try {
    const cacheKey = `timeline_${query}`;
    const cacheEntry: CacheEntry = {
      response,
      timestamp: Date.now(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cacheEntry));
  } catch (error) {
    console.error('Error saving to cache:', error);
  }
};

// Clear expired cache entries
const clearExpiredCache = () => {
  try {
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key?.startsWith('timeline_')) {
        const cached = localStorage.getItem(key);
        if (cached) {
          const { timestamp }: CacheEntry = JSON.parse(cached);
          if (Date.now() - timestamp > CACHE_DURATION) {
            localStorage.removeItem(key);
          }
        }
      }
    }
  } catch (error) {
    console.error('Error clearing cache:', error);
  }
};

export const generateEvents = async (
  query: GetTimelineRequest
): Promise<GetTimelineResponse> => {
  // Clear expired cache entries periodically
  clearExpiredCache();

  // Check cache first
  const cachedResponse = getFromCache(query.query);
  if (cachedResponse) {
    console.log('Using cached timeline for:', query.query);
    return cachedResponse;
  }

  const response = await fetch(
    'https://europe-west2-solulu-prod.cloudfunctions.net/getTimeline',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    }
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch timeline events: ${response.statusText}`);
  }

  const data = (await response.json()) as GetTimelineResponse;

  const responseWithDates = {
    events: data.events.map((event) => ({
      ...event,
      date: new Date(event.date),
    })),
  };

  // Save to cache
  saveToCache(query.query, responseWithDates);

  return responseWithDates;
};
