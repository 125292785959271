import React, { useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/16/solid';

interface CorrelationScoreCardProps {
  correlationScore: number | null;
}

const CorrelationScoreCard: React.FC<CorrelationScoreCardProps> = ({
  correlationScore,
}) => {
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="group bg-white p-8 rounded-2xl shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 hover:border-blue-100 hover:-translate-y-1">
      <h3 className="text-2xl font-semibold mb-6 text-gray-900 tracking-tight group-hover:text-blue-600 transition-colors duration-300">
        Pearson Correlation Score
      </h3>
      {correlationScore !== null ? (
        <>
          <div className="text-4xl font-extrabold text-blue-600 text-center">
            {correlationScore.toFixed(2)}
          </div>
          <div className="flex justify-center">
            <button
              onClick={() => setShowInfo(!showInfo)}
              className="mt-4 text-blue-600 hover:text-blue-700 flex items-center space-x-1.5 transition-colors duration-300"
            >
              <InformationCircleIcon className="h-5 w-5" />
              <span>What does this mean?</span>
            </button>
          </div>
          {showInfo && (
            <div className="text-gray-600 text-sm mt-4 text-center">
              A score of <strong>1</strong> means prices rise with events.
              <br />
              <strong>0</strong> means no relationship.
              <br />
              <strong>-1</strong> means prices fall with events.
            </div>
          )}
        </>
      ) : (
        <div className="text-gray-600 text-center">Calculating...</div>
      )}
    </div>
  );
};

export default CorrelationScoreCard;
