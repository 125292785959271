import React from 'react';
import { Mixpanel } from '../services/Mixpanel';

const Header: React.FC = () => {
  return (
    <header className="sticky top-0 z-50 backdrop-blur-md bg-white/10 shadow-sm border-b border-gray-200/20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo or Title */}
          <div className="flex-shrink-0">
            <a
              href="/"
              className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent hover:from-blue-700 hover:to-purple-700 transition-all duration-300"
            >
              solulu
            </a>
          </div>

          {/* Navigation and User Profile */}
          <nav className="flex items-center space-x-4">
            <a
              href="/timeline"
              className="inline-block"
              onClick={() => Mixpanel.track('new_timeline_button_clicked')}
            >
              <button className="px-5 py-2 text-sm font-medium text-white bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg shadow-sm hover:from-blue-700 hover:to-purple-700 hover:shadow-md transition-all duration-300 transform hover:-translate-y-0.5">
                New Timeline
              </button>
            </a>

            <a
              href="https://forms.gle/yu2MbDWQoWNLjSaq5"
              target="_blank"
              onClick={() => Mixpanel.track('give_feedback_button_clicked')}
              rel="noopener noreferrer"
              className="inline-block"
            >
              <button className="px-5 py-2 text-sm font-medium text-gray-700 bg-white rounded-lg shadow-sm hover:bg-gray-50 hover:shadow-md transition-all duration-300 transform hover:-translate-y-0.5 border border-gray-200">
                Give Feedback
              </button>
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
