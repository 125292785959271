import React from 'react';

interface StockSelectorCardProps {
  selectedStock: string;
  setSelectedStock: (stock: string) => void;
  stockOptions: string[];
  className?: string;
}

const StockSelectorCard: React.FC<StockSelectorCardProps> = ({
  selectedStock,
  setSelectedStock,
  stockOptions,
  className = '',
}) => {
  return (
    <div className={`relative ${className}`}>
      <select
        value={selectedStock}
        onChange={(e) => setSelectedStock(e.target.value)}
        className="w-32 pl-3 pr-8 py-2.5 bg-white border border-gray-200
          focus:border-[#4263EB] focus:ring-1 focus:ring-[#4263EB]/20
          rounded-lg text-gray-900 text-sm appearance-none
          transition-all duration-200"
      >
        {stockOptions.map((stock) => (
          <option key={stock} value={stock}>
            {stock}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StockSelectorCard;
