import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { HistoricalEvent, StockPrices } from '../../types';

interface StockPriceGraphProps {
  stockPrices: StockPrices;
  historicalEvents: HistoricalEvent[];
}

const StockPriceGraph: React.FC<StockPriceGraphProps> = ({
  stockPrices,
  historicalEvents,
}) => {
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (historicalEvents.length && stockPrices) {
      const eventDates = historicalEvents.map((event) => new Date(event.date));
      const earliestEvent = new Date(
        Math.min(...eventDates.map((d) => d.getTime()))
      );
      const latestEvent = new Date(
        Math.max(...eventDates.map((d) => d.getTime()))
      );

      // Set buffer dates (10 days before and after)
      const startDate = new Date(earliestEvent);
      startDate.setDate(startDate.getDate() - 10);

      const endDate = new Date(latestEvent);
      endDate.setDate(endDate.getDate() + 10);

      // Calculate zoom percentages based on the date range
      const allDates = stockPrices.sortedPriceDates;
      const startIndex = allDates.findIndex((date: Date) => date >= startDate);
      const endIndex = allDates.findIndex((date: Date) => date >= endDate);

      const zoomStart = (startIndex / allDates.length) * 100;
      const zoomEnd = (endIndex / allDates.length) * 100;

      const dates = stockPrices.sortedPriceDates.map((date) =>
        date.toLocaleDateString('en-CA')
      );
      const prices = stockPrices.sortedPrices;

      // Prepare mark lines for historical events
      const eventLines = historicalEvents.map((event) => ({
        xAxis: event.date.toLocaleDateString('en-CA'),
        lineStyle: { color: '#FF6347', type: 'dashed' },
        label: {
          show: false,
        },
        tooltip: {
          show: true,
          formatter: event.title,
          backgroundColor: '#333',
          borderColor: '#ddd',
          textStyle: { color: '#fff' },
        },
      }));

      setChartOptions({
        tooltip: {
          trigger: 'axis',
          formatter: '{b}: ${c}',
          backgroundColor: '#333',
          borderColor: '#ddd',
          textStyle: {
            color: '#fff',
          },
        },
        xAxis: {
          type: 'category',
          data: dates,
          axisLine: {
            lineStyle: { color: '#ccc' },
          },
          axisLabel: {
            formatter: (value: string) => {
              const date = new Date(value);
              return date.toLocaleDateString('en-US', {
                month: '2-digit',
                year: 'numeric',
              });
            },
            color: '#888',
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: { color: '#ccc' },
          },
          axisLabel: {
            formatter: '${value}',
            color: '#888',
          },
        },
        series: [
          {
            data: prices,
            type: 'line',
            smooth: true,
            lineStyle: {
              color: '#4a90e2',
              width: 3,
            },
            itemStyle: {
              color: '#4a90e2',
              borderColor: '#fff',
              borderWidth: 2,
            },
            areaStyle: {
              color: 'rgba(74, 144, 226, 0.2)',
            },
            markLine: {
              data: eventLines,
              tooltip: {
                trigger: 'item',
              },
            },
          },
        ],
        dataZoom: [
          {
            type: 'slider',
            show: true,
            xAxisIndex: 0,
            start: zoomStart,
            end: zoomEnd,
            handleIcon: 'M10.7,11.9h-1.4v-1.4h1.4V11.9z',
            handleSize: '80%',
            handleStyle: {
              color: '#333',
              borderColor: '#ddd',
            },
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            start: zoomStart,
            end: zoomEnd,
          },
        ],
        grid: {
          left: '3%',
          right: '3%',
          bottom: '10%',
          containLabel: true,
        },
      });
    }
  }, [historicalEvents, stockPrices]);

  return (
    <div className="group bg-white p-8 rounded-2xl shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 hover:border-blue-100 hover:-translate-y-1">
      <h3 className="text-2xl font-semibold mb-6 text-gray-900 tracking-tight group-hover:text-blue-600 transition-colors duration-300">
        Stock price
      </h3>
      <ReactEcharts
        option={chartOptions}
        style={{ height: '400px', width: '100%' }}
        notMerge={true}
      />
    </div>
  );
};

export default StockPriceGraph;
