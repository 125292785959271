import React, { useState, useEffect, useCallback, useRef } from 'react';
import { generateEvents } from '../../services/generateEvents';
import { HistoricalEvent } from '../../types';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import LoadingSpinner from '../LoadingSpinner';
import { Mixpanel } from '../../services/Mixpanel';

// Cache duration in milliseconds (e.g., 1 hour)
const CACHE_DURATION = 60 * 60 * 1000;

interface CacheEntry {
  events: HistoricalEvent[];
  timestamp: number;
}

const getFromCache = (query: string): HistoricalEvent[] | null => {
  try {
    const cacheKey = `events_${query}`;
    const cached = localStorage.getItem(cacheKey);
    if (!cached) return null;

    const { events, timestamp }: CacheEntry = JSON.parse(cached);

    // Check if cache is still valid
    if (Date.now() - timestamp > CACHE_DURATION) {
      localStorage.removeItem(cacheKey);
      return null;
    }

    // Convert date strings back to Date objects
    return events.map((event) => ({
      ...event,
      date: new Date(event.date),
    }));
  } catch (error) {
    console.error('Error reading from cache:', error);
    return null;
  }
};

const saveToCache = (query: string, events: HistoricalEvent[]) => {
  try {
    const cacheKey = `events_${query}`;
    const cacheEntry: CacheEntry = {
      events,
      timestamp: Date.now(),
    };
    localStorage.setItem(cacheKey, JSON.stringify(cacheEntry));
  } catch (error) {
    console.error('Error saving to cache:', error);
  }
};

interface HypothesisCardProps {
  hypothesis: string;
  setHypothesis: (value: string) => void;
  events: HistoricalEvent[];
  setEvents: (events: HistoricalEvent[]) => void;
  className?: string;
  suggestionsClassName?: string;
}

const HypothesisCard: React.FC<HypothesisCardProps> = ({
  hypothesis,
  setHypothesis,
  setEvents,
  className = '',
  suggestionsClassName = '',
}) => {
  const [localHypothesis, setLocalHypothesis] = useState<string>(hypothesis);
  const [loading, setLoading] = useState<boolean>(false);
  const initialLoadDone = useRef<boolean>(false);

  const suggestedHypotheses = [
    'Elon Musk tweets',
    'Nancy Pelosi buys or sells a stock',
    'McDonalds brings back the McRib',
    'Apple launch event',
    'Netflix releases a show',
  ];

  const fetchEvents = useCallback(
    async (query: string) => {
      if (!query.trim() || loading) return;

      // Check cache first
      const cachedEvents = getFromCache(query);
      if (cachedEvents) {
        console.log('Using cached events for:', query);
        setEvents(cachedEvents);
        Mixpanel.track('events_fetched_from_cache', { query });
        return;
      }

      setLoading(true);
      try {
        const fetchedEvents = await generateEvents({ query });
        Mixpanel.track('events_fetched', {
          query,
          events: fetchedEvents.events,
        });

        // Save to cache
        saveToCache(query, fetchedEvents.events);

        setEvents(fetchedEvents.events);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    },
    [setEvents, loading]
  );

  const handleSave = useCallback(async () => {
    if (!localHypothesis.trim() || loading) return;

    setHypothesis(localHypothesis);
    Mixpanel.track('event_search', { query: localHypothesis });
    await fetchEvents(localHypothesis);
  }, [localHypothesis, setHypothesis, fetchEvents, loading]);

  // Handle initial load
  useEffect(() => {
    if (!initialLoadDone.current && hypothesis) {
      initialLoadDone.current = true;
      fetchEvents(hypothesis);
    }
  }, [hypothesis, fetchEvents]);

  // Update local hypothesis when prop changes
  useEffect(() => {
    setLocalHypothesis(hypothesis);
  }, [hypothesis]);

  // Clear old cache entries periodically
  useEffect(() => {
    const clearOldCache = () => {
      try {
        for (let i = 0; i < localStorage.length; i++) {
          const key = localStorage.key(i);
          if (key?.startsWith('events_')) {
            const cached = localStorage.getItem(key);
            if (cached) {
              const { timestamp }: CacheEntry = JSON.parse(cached);
              if (Date.now() - timestamp > CACHE_DURATION) {
                localStorage.removeItem(key);
              }
            }
          }
        }
      } catch (error) {
        console.error('Error clearing cache:', error);
      }
    };

    clearOldCache();
  }, []);

  const handleChipClick = async (suggestedText: string) => {
    if (loading) return;

    setLocalHypothesis(suggestedText);
    setHypothesis(suggestedText);
    Mixpanel.track('suggestion_clicked', { query: suggestedText });
    await fetchEvents(suggestedText);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSave();
    }
  };

  return (
    <div className={`${className}`}>
      {/* Search Field */}
      <div className="relative">
        <div className="relative flex items-center">
          <div className="absolute left-3 text-gray-400">
            <MagnifyingGlassIcon className="h-4 w-4" />
          </div>
          <input
            type="text"
            placeholder="Describe any market scenario (e.g., 'When Elon Musk tweets')"
            value={localHypothesis}
            onChange={(e) => setLocalHypothesis(e.target.value)}
            className="w-full pl-9 pr-4 py-2.5 bg-white border border-gray-200
              focus:border-[#4263EB] focus:ring-1 focus:ring-[#4263EB]/20
              rounded-lg text-gray-900 placeholder-gray-500 text-sm
              transition-all duration-200"
            onKeyDown={handleKeyDown}
          />
          {loading && (
            <div className="absolute right-3">
              <LoadingSpinner size={16} color="#4263EB" />
            </div>
          )}
        </div>
      </div>

      {/* Suggested Chips */}
      <div className={`flex flex-wrap gap-1.5 ${suggestionsClassName}`}>
        {suggestedHypotheses.map((suggestion, index) => (
          <button
            key={index}
            onClick={() => handleChipClick(suggestion)}
            className="px-3 py-1.5 text-xs text-white font-medium rounded-md
              bg-gradient-to-r from-blue-600 to-purple-600
              hover:from-blue-700 hover:to-purple-700 shadow-sm hover:shadow-md
              transition-all duration-300 transform hover:-translate-y-0.5"
          >
            {suggestion}
          </button>
        ))}
      </div>
    </div>
  );
};

export default HypothesisCard;
