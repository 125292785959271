import React, { useEffect, useState } from 'react';
import { StockPrices, HistoricalEvent } from '../../types';

interface GainsCardProps {
  stockPrices: StockPrices;
  benchmarkStockPrices: StockPrices;
  events: HistoricalEvent[];
  initialDollarAmount: number;
}

const GainsCard: React.FC<GainsCardProps> = ({
  stockPrices,
  benchmarkStockPrices,
  events,
  initialDollarAmount,
}) => {
  const [gainsAmount, setGainsAmount] = useState<number | null>(null);
  const [benchmarkGains, setBenchmarkGains] = useState<number | null>(null);
  const [gainsPercentage, setGainsPercentage] = useState<number | null>(null);
  const [benchmarkPercentage, setBenchmarkPercentage] = useState<number | null>(
    null
  );
  const [dollarAmount, setDollarAmount] = useState<number>(initialDollarAmount);

  const findClosestPrice = (
    eventDate: Date,
    sortedDates: Date[],
    sortedPrices: number[]
  ): number | null => {
    let closestPrice: number | null = null;
    for (let i = sortedDates.length - 1; i >= 0; i--) {
      if (sortedDates[i] <= eventDate) {
        closestPrice = sortedPrices[i];
        break;
      }
    }
    return closestPrice;
  };

  const calculateGains = (
    stockPrices: StockPrices,
    events: HistoricalEvent[],
    dollarAmount: number
  ): { absoluteGains: number; percentageGains: number } => {
    const { sortedPriceDates, sortedPrices } = stockPrices;
    const latestPrice = sortedPrices[sortedPrices.length - 1];

    let totalInvested = 0;
    let totalCurrentValue = 0;

    events.forEach((event) => {
      const eventDate = event.date;
      const eventPrice = findClosestPrice(
        eventDate,
        sortedPriceDates,
        sortedPrices
      );

      if (eventPrice !== null) {
        const sharesBought = dollarAmount / eventPrice;
        const currentValue = sharesBought * latestPrice;

        totalInvested += dollarAmount;
        totalCurrentValue += currentValue;
      }
    });

    const absoluteGains = totalCurrentValue - totalInvested;
    const percentageGains =
      totalInvested > 0 ? (absoluteGains / totalInvested) * 100 : 0;

    return { absoluteGains, percentageGains };
  };

  useEffect(() => {
    if (stockPrices && events.length > 0 && dollarAmount) {
      const stockGains = calculateGains(stockPrices, events, dollarAmount);
      setGainsAmount(stockGains.absoluteGains);
      setGainsPercentage(stockGains.percentageGains);

      const benchmarkGains = calculateGains(
        benchmarkStockPrices,
        events,
        dollarAmount
      );
      setBenchmarkGains(benchmarkGains.absoluteGains);
      setBenchmarkPercentage(benchmarkGains.percentageGains);
    }
  }, [stockPrices, benchmarkStockPrices, events, dollarAmount]);

  const handleDollarAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDollarAmount(Number(e.target.value));
  };

  const isStockBetter =
    gainsAmount !== null &&
    benchmarkGains !== null &&
    gainsAmount > benchmarkGains;

  return (
    <div className="group bg-white p-8 rounded-2xl shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 hover:border-blue-100 hover:-translate-y-1 max-w-4xl mx-auto">
      <h3 className="text-2xl font-semibold mb-6 text-gray-900 tracking-tight text-center lg:text-left group-hover:text-blue-600 transition-colors duration-300">
        Net Profit Comparison
      </h3>

      {/* Dollar Amount Input */}
      <div className="flex flex-col lg:flex-row items-center justify-between mb-6">
        <label
          className="text-gray-600 text-sm mb-2 lg:mb-0 lg:mr-4 w-full lg:w-auto text-center lg:text-left"
          htmlFor="dollarAmount"
        >
          Number of shares you would have bought at each event:
        </label>
        <input
          id="dollarAmount"
          type="number"
          value={dollarAmount}
          onChange={handleDollarAmountChange}
          className="border border-gray-200 rounded-xl px-4 py-2 text-center w-full lg:w-32 focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 hover:border-blue-200"
          min="0"
        />
      </div>

      {/* Gains Comparison */}
      {gainsAmount !== null &&
      benchmarkGains !== null &&
      gainsPercentage !== null &&
      benchmarkPercentage !== null ? (
        <div className="flex flex-col lg:flex-row items-center justify-between mt-6 gap-8">
          {/* Selected Stock Gains */}
          <div className="text-center lg:w-1/2">
            <div
              className={`text-4xl font-extrabold ${
                isStockBetter ? 'text-green-600' : 'text-red-600'
              }`}
            >
              <div>{Math.round(gainsPercentage)}%</div>
              <div className="text-3xl">
                (${Math.round(gainsAmount).toLocaleString()})
              </div>
            </div>
            <p className="text-gray-600 text-sm mt-2">Selected Stock</p>
          </div>

          {/* Benchmark Gains */}
          <div className="text-center lg:w-1/2 border-t border-gray-200 pt-4 lg:pt-0 lg:border-t-0 lg:border-l lg:pl-6">
            <div
              className={`text-4xl font-extrabold ${
                isStockBetter ? 'text-red-600' : 'text-green-600'
              }`}
            >
              <div>{Math.round(benchmarkPercentage)}%</div>
              <div className="text-3xl">
                (${Math.round(benchmarkGains).toLocaleString()})
              </div>
            </div>
            <p className="text-gray-600 text-sm mt-2">S&P 500 Benchmark</p>
          </div>
        </div>
      ) : (
        <div className="text-gray-600 text-center mt-6">Calculating...</div>
      )}
    </div>
  );
};

export default GainsCard;
