import React, { useState, useEffect, useCallback } from 'react';
import {
  ArrowRightIcon,
  ChartBarIcon,
  LightBulbIcon,
  BellIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import HypothesisCard from './cards/HypothesisCard';
import StockSelectorCard from './cards/StockSelectorCard';
import StockPriceGraph from './cards/StockPriceGraph';
import GainsCard from './cards/GainsCard';
import AverageChangeCard from './cards/AverageChangeCard';
import CorrelationScoreCard from './cards/CorrelationScoreCard';
import Timeline from './Timeline';
import { getStockPrices } from '../services/getStockPrices';
import { HistoricalEvent, StockPrices } from '../types';
import { getCorrelation } from '../services/getCorrelation';
import { getAverageChangeAfterEvents } from '../services/getAverageChangeAfterEvents';
import { stockTickers } from '../services/stockTickers';
import mixpanel from 'mixpanel-browser';
import LoadingSpinner from './LoadingSpinner';
import { useNavigate, useParams } from 'react-router-dom';

interface PreviousIdea {
  id: string;
  hypothesis: string;
  stock: string;
  correlation: number;
}

const MOCK_PREVIOUS_IDEAS: PreviousIdea[] = [
  {
    id: '1',
    hypothesis: 'When interest rates rise, bank stocks go up',
    stock: 'JPM',
    correlation: 0.75,
  },
  {
    id: '2',
    hypothesis: 'AI announcements boost tech stocks',
    stock: 'NVDA',
    correlation: 0.82,
  },
  {
    id: '3',
    hypothesis: 'Oil price increases affect airline stocks',
    stock: 'DAL',
    correlation: -0.68,
  },
  {
    id: '4',
    hypothesis: 'Apple product launches impact supplier stocks',
    stock: 'QCOM',
    correlation: 0.64,
  },
  {
    id: '5',
    hypothesis: 'FDA drug approvals boost biotech stocks',
    stock: 'MRNA',
    correlation: 0.91,
  },
  {
    id: '6',
    hypothesis: 'Extreme weather events affect insurance stocks',
    stock: 'PGR',
    correlation: -0.45,
  },
  {
    id: '7',
    hypothesis: 'Gaming releases impact semiconductor stocks',
    stock: 'AMD',
    correlation: 0.58,
  },
];

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { query, stock } = useParams<{ query?: string; stock?: string }>();

  // Get initial values from URL params
  const initialHypothesis = query ? decodeURIComponent(query) : '';
  const initialStock = stock || 'AAPL';

  const [hypothesis, setHypothesis] = useState<string>(initialHypothesis);
  const [events, setEvents] = useState<HistoricalEvent[]>([]);
  const [selectedStock, setSelectedStock] = useState<string>(initialStock);
  const [stockPrices, setStockPrices] = useState<StockPrices | undefined>();
  const [benchmarkPrices, setBenchmarkPrices] = useState<
    StockPrices | undefined
  >();
  const [correlationScore, setCorrelationScore] = useState<number | null>(null);
  const [averageChange, setAverageChange] = useState<number | null>(null);
  const [isNotifyPopupOpen, setIsNotifyPopupOpen] = useState(false);
  const [email, setEmail] = useState<string>('');
  const [isSearching, setIsSearching] = useState(false);
  const [shuffledIdeas, setShuffledIdeas] = useState<PreviousIdea[]>([]);

  const handleSearch = useCallback(async () => {
    setIsSearching(true);
    const input = document.querySelector('input[type="text"]');
    if (input) {
      input.dispatchEvent(
        new KeyboardEvent('keydown', {
          key: 'Enter',
          code: 'Enter',
          keyCode: 13,
          bubbles: true,
        })
      );
    }
  }, []);

  // Update URL when hypothesis or stock changes
  useEffect(() => {
    if (hypothesis || selectedStock !== 'AAPL') {
      const path = `/hypothesis/${encodeURIComponent(hypothesis)}/${selectedStock}`;
      navigate(path, { replace: true });
    }
  }, [hypothesis, selectedStock, navigate]);

  // Load data from URL params on mount
  useEffect(() => {
    if (initialHypothesis) {
      handleSearch();
    }
  }, [initialHypothesis, handleSearch]);

  useEffect(() => {
    const fetchBenchmarkPrices = async () => {
      try {
        const benchmark = await getStockPrices('VOO');
        setBenchmarkPrices(benchmark);
      } catch (error) {
        console.error('Failed to fetch benchmark prices:', error);
      }
    };
    fetchBenchmarkPrices();
  }, []);

  useEffect(() => {
    const fetchStockPrices = async () => {
      try {
        setStockPrices(undefined); // Clear previous prices while loading
        const prices = await getStockPrices(selectedStock);
        setStockPrices(prices);

        if (events.length && prices) {
          const eventDates = events.map((event) => new Date(event.date));
          setCorrelationScore(getCorrelation(prices, eventDates));
          setAverageChange(getAverageChangeAfterEvents(prices, events));
        }
      } catch (error) {
        console.error('Failed to fetch stock prices:', error);
      }
    };

    if (selectedStock) {
      console.log('Fetching prices for:', selectedStock); // Debug log
      fetchStockPrices();
    }
  }, [selectedStock, events]); // Make sure selectedStock is in dependencies

  useEffect(() => {
    const shuffleArray = (array: PreviousIdea[]) => {
      const shuffled = [...array];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
      }
      return shuffled;
    };
    setShuffledIdeas(shuffleArray(MOCK_PREVIOUS_IDEAS));
  }, []);

  const handleEmailSubmit = () => {
    if (email) {
      mixpanel.track('notify_alert_email_submission_clicked', {
        email: email,
        stock: selectedStock,
        query: hypothesis,
      });
      alert(
        "Thanks for your interest! We'll notify you when this feature is ready."
      );
      setIsNotifyPopupOpen(false);
      setEmail('');
    } else {
      alert('Please enter a valid email address.');
    }
  };

  const handleIdeaClick = (idea: PreviousIdea) => {
    setHypothesis(idea.hypothesis);
    setSelectedStock(idea.stock);
    const searchEvent = new Event('submit', { bubbles: true });
    const hypothesisForm = document.querySelector('form');
    if (hypothesisForm) {
      hypothesisForm.dispatchEvent(searchEvent);
    }
  };

  useEffect(() => {
    if (events.length > 0) {
      setIsSearching(false);
    }
  }, [events]);

  return (
    <div className="min-h-full bg-gray-50">
      {/* Search Section */}
      <div className="bg-white border-b border-gray-100">
        <div className="w-full max-w-[1400px] mx-auto px-6 sm:px-8 lg:px-12 py-6">
          <div className="flex flex-col space-y-4">
            {/* Title */}
            <h1 className="text-2xl font-medium text-gray-900">
              Describe a scenario
            </h1>

            {/* Search Bar and Stock Selector */}
            <div className="flex flex-col space-y-3">
              <div className="flex-1 min-w-0">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    const hypothesisCard =
                      document.querySelector('input[type="text"]');
                    if (hypothesisCard) {
                      hypothesisCard.dispatchEvent(
                        new KeyboardEvent('keydown', {
                          key: 'Enter',
                          code: 'Enter',
                          keyCode: 13,
                          bubbles: true,
                        })
                      );
                    }
                  }}
                >
                  <div className="flex gap-2">
                    <div className="flex-1">
                      <HypothesisCard
                        hypothesis={hypothesis}
                        setHypothesis={setHypothesis}
                        events={events}
                        setEvents={setEvents}
                        className="border-none shadow-none p-0 hover:shadow-none"
                        suggestionsClassName="mt-2"
                      />
                    </div>
                    <div className="flex items-start gap-2">
                      <StockSelectorCard
                        selectedStock={selectedStock}
                        setSelectedStock={setSelectedStock}
                        stockOptions={stockTickers}
                        className="border-none shadow-none p-0 hover:shadow-none hover:scale-100"
                      />
                      <button
                        onClick={handleSearch}
                        disabled={isSearching}
                        className="w-10 h-10 flex items-center justify-center
                          bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-lg
                          hover:from-blue-700 hover:to-purple-700 shadow-sm hover:shadow-md
                          transition-all duration-300 transform hover:-translate-y-0.5
                          disabled:opacity-50 disabled:cursor-not-allowed disabled:transform-none
                          disabled:hover:shadow-sm"
                        aria-label="Analyze"
                      >
                        {isSearching ? (
                          <LoadingSpinner size={16} color="#ffffff" />
                        ) : (
                          <MagnifyingGlassIcon className="h-5 w-5" />
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              {/* Description Text */}
              <p className="text-gray-500 text-sm">
                Type any scenario, no matter how wild, and our AI will dig
                through millions of news articles, webpages and historical
                records to find every time it happened!
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="w-full max-w-[1400px] mx-auto px-6 sm:px-8 lg:px-12 py-8">
        {/* Results Section */}
        {events.length > 0 && (
          <div className="space-y-8">
            {/* Main Analysis Section */}
            <div className="bg-white rounded-xl border border-gray-100 p-6">
              <div className="flex items-center mb-6">
                <ChartBarIcon className="h-5 w-5 text-[#4263EB] mr-3" />
                <h2 className="text-xl font-medium text-gray-900">
                  Market Analysis
                </h2>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                {stockPrices && benchmarkPrices && (
                  <div className="h-[500px]">
                    <StockPriceGraph
                      stockPrices={stockPrices}
                      historicalEvents={events}
                    />
                  </div>
                )}
                <div className="space-y-6">
                  {stockPrices && benchmarkPrices && (
                    <>
                      <GainsCard
                        stockPrices={stockPrices}
                        benchmarkStockPrices={benchmarkPrices}
                        events={events}
                        initialDollarAmount={500}
                      />
                      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                        <AverageChangeCard averageChange={averageChange} />
                        <CorrelationScoreCard
                          correlationScore={correlationScore}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Timeline Section */}
            <div className="bg-white rounded-xl border border-gray-100 p-6">
              <Timeline events={events} />
            </div>
          </div>
        )}

        {/* Community Insights Section */}
        <div className="mt-12">
          <div className="flex items-center mb-6">
            <LightBulbIcon className="h-5 w-5 text-[#4263EB] mr-3" />
            <h2 className="text-xl font-medium text-gray-900">
              Community Insights
            </h2>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {shuffledIdeas.map((idea) => (
              <div
                key={idea.id}
                onClick={() => handleIdeaClick(idea)}
                className="bg-white rounded-xl border border-gray-100 p-6
                  cursor-pointer hover:border-[#4263EB]/20 transition-colors duration-200
                  hover:shadow-[0_0_20px_rgba(66,99,235,0.1)] group"
              >
                <div className="flex items-start mb-4">
                  <div className="bg-[#4263EB]/5 rounded-lg px-3 py-2">
                    <span className="text-[#4263EB] font-medium">
                      {idea.stock}
                    </span>
                  </div>
                </div>
                <p className="font-medium text-gray-800 mb-4 line-clamp-2">
                  {idea.hypothesis}
                </p>
                <div className="flex justify-end items-center">
                  <div className="flex items-center text-[#4263EB] font-medium group-hover:translate-x-0.5 transition-transform duration-200">
                    Explore
                    <ArrowRightIcon className="h-4 w-4 ml-1.5" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Notify Popup */}
      {isNotifyPopupOpen && (
        <div className="fixed inset-0 bg-gray-900/75 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-md mx-4">
            <div className="p-4 sm:p-6">
              <div className="flex items-center mb-4">
                <BellIcon className="h-5 w-5 sm:h-6 sm:w-6 text-blue-600 mr-2" />
                <h2 className="text-lg sm:text-xl font-semibold text-gray-900">
                  Stay Updated
                </h2>
              </div>
              <p className="text-gray-600 mb-6 text-sm sm:text-base">
                We&apos;re working on this feature. Enter your email below, and
                we&apos;ll notify you when it&apos;s ready!
              </p>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full border border-gray-200 rounded-lg px-4 py-3 mb-4
                  focus:ring-2 focus:ring-blue-500 focus:outline-none
                  transition-all duration-300 text-sm sm:text-base"
              />
              <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-4">
                <button
                  onClick={handleEmailSubmit}
                  className="w-full sm:w-auto bg-blue-600 text-white font-semibold
                    rounded-lg py-3 px-6 shadow-sm hover:bg-blue-700
                    transition-all duration-300"
                >
                  Submit
                </button>
                <button
                  onClick={() => setIsNotifyPopupOpen(false)}
                  className="w-full sm:w-auto bg-gray-100 text-gray-700 font-semibold
                    rounded-lg py-3 px-6 hover:bg-gray-200
                    transition-all duration-300"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
