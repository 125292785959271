import axios from 'axios';
import { StockPrices } from '../types';

type CachedStockData = {
  priceMap: [string, number][]; // Array of [dateString, price] tuples
  sortedPriceDates: string[];
  sortedPrices: number[];
};

export const getStockPrices = async (
  stockTicker: string
): Promise<StockPrices> => {
  const cacheKey = `stockPrices_${stockTicker}`;
  const cachedData = localStorage.getItem(cacheKey);

  if (cachedData) {
    console.log(`Loaded cached prices for stock: ${stockTicker}`);
    const parsedData = JSON.parse(cachedData) as CachedStockData;

    // Reconstruct the Map with proper types
    const priceMap = new Map<Date, number>(
      parsedData.priceMap.map(([dateStr, price]) => [new Date(dateStr), price])
    );

    return {
      priceMap,
      sortedPriceDates: parsedData.sortedPriceDates.map(
        (date: string) => new Date(date)
      ),
      sortedPrices: parsedData.sortedPrices,
    };
  }

  const response = await axios.get(
    `https://financialmodelingprep.com/api/v3/historical-price-full/${stockTicker}`,
    {
      params: {
        apikey: process.env.REACT_APP_FMP_API_KEY,
        serietype: 'line',
      },
    }
  );

  const historicalData = response.data.historical;
  if (!historicalData) {
    console.error('Error: Invalid API response.', response);
    throw Error('Could not fetch prices for: ' + stockTicker);
  }

  const priceMap = new Map<Date, number>();
  const sortedPriceDates: Date[] = [];
  const sortedPrices: number[] = [];

  historicalData.reverse().forEach((entry: { date: string; close: number }) => {
    const date = new Date(entry.date);
    const price = entry.close;
    priceMap.set(date, price);
    sortedPriceDates.push(date);
    sortedPrices.push(price);
  });

  const stockPrices: StockPrices = { priceMap, sortedPriceDates, sortedPrices };

  localStorage.setItem(
    cacheKey,
    JSON.stringify({
      priceMap: Array.from(priceMap.entries()),
      sortedPriceDates: sortedPriceDates,
      sortedPrices: sortedPrices,
    })
  );

  return stockPrices;
};
