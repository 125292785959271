import React from 'react';

interface AverageChangeCardProps {
  averageChange: number | null;
}

const AverageChangeCard: React.FC<AverageChangeCardProps> = ({
  averageChange,
}) => (
  <div className="group bg-white p-8 rounded-2xl shadow-sm hover:shadow-md transition-all duration-300 border border-gray-100 hover:border-purple-100 hover:-translate-y-1">
    <h3 className="text-2xl font-semibold mb-6 text-gray-900 tracking-tight group-hover:text-purple-600 transition-colors duration-300">
      Change in Stock Price Day After Event
    </h3>
    {averageChange !== null ? (
      <div className="text-4xl font-extrabold text-purple-600 text-center">
        {averageChange.toFixed(2)}%
      </div>
    ) : (
      <div className="text-gray-600 text-center">Calculating...</div>
    )}
  </div>
);

export default AverageChangeCard;
